import React from "react";

function Footer() {
  return (
    <div class="text-center p-6 bg-black footer">
      <span class="text-white">&copy; 2024 Copyright - Saransh Koirala </span>
    </div>
  );
}

export default Footer;
